import React from "react"

import Layout from "../components/layout"
import { Blob } from "../components/atoms"

const NotFoundPage = () => (
  <Layout>
    <div className="mt-16 flex justify-center">
      <div className="absolute">
        <Blob />
      </div>
      <h1 className="mt-12 md:mt-16 lg:mt-20 mx-auto text-center font-bold text-on-accent text-6xl">
        404
      </h1>
    </div>
    <p className="mt-20 lg:mt-24 pb-48 font-medium text-center text-2xl text-secondary">
      Oh no, the page you’re looking for doesn't exist.
    </p>
  </Layout>
)

export default NotFoundPage
